export default {
    home: '/',
    auth: {
        login: '/login/',
        enterWorkspace: '/enter-workspace/',
        confirmEmail: '/confirm-email/',
        forgotPassword: '/forgot-password/',
        joinWorkspace: '/join-workspace/',
        visitorSettings: '/visitor-settings/',
        emailVerification: '/email-verification/',
    },
    reports: {
        root: '/reports/',
        create: '/reports/create/',
        download: '/reports/download/:id',
    },
    intercomSettings: {
        root: '/intercom-settings/',
        frontDesk: '/intercom-settings/front-desk/',
        frontDescSettings: '/intercom-settings/front-desc/:siteId',
        access: '/visitor-access/',
        uploadTenants: '/intercom-settings/tenants/upload/',
        addTenant: '/intercom-settings/tenants/new/',
        editTenant: '/intercom-settings/tenants/:id/',
        qr: '/intercom-settings/qr/',
        qrDetails: '/intercom-settings/qr/:id/',
    },
    visitorPins: {
        root: '/visitor-pins/',
        settings: '/visitor-pins/settings/',
        addPin: '/visitor-pins/add-pin/',
        pinDetails: '/visitor-pins/pins/:id/',
    },
    userGroups: {
        root: '/user-groups/',
        users: '/user-groups/:id/users/',
        accessPoints: '/user-groups/:id/access-points/',
        floors: '/user-groups/:id/floors/',
        addAccessPoints: '/user-groups/:id/access-points/add/',
        addUsers: '/user-groups/:id/users/add/',
        addFloors: '/user-groups/:id/floors/add/',
    },
    hardware: {
        root: '/hardware/',
        readers: {
            root: '/hardware/key-card-readers/',
            add: '/hardware/key-card-readers/add/',
            edit: '/hardware/key-card-readers/:id/',
        },
        smartLocks: {
            root: '/hardware/smart-locks/',
            details: '/hardware/smart-locks/:id/',
            pins: '/hardware/smart-locks/:id/pins/',
            logs: '/hardware/smart-locks/:id/logs/',
        },
        doorControllers: {
            root: '/hardware/door-controllers/',
            details: '/hardware/door-controllers/:deviceId/',
            telemetry: '/hardware/door-controllers/:deviceId/telemetry/',
            swiftReaderX: '/hardware/swiftreaderx/',
            swiftReaderXDetails: '/hardware/X915/:deviceId/',
        },
        intercomReaders: {
            root: '/hardware/intercom-readers/',
            details: '/hardware/intercom-readers/:id/',
        },
        keyCards: {
            root: '/hardware/key-cards/',
            add: '/hardware/key-cards/add/',
            settings: '/hardware/key-cards/settings/',
            upload: '/hardware/key-cards/upload/',
            edit: '/hardware/key-cards/:id/',
            unknownCard: '/hardware/key-cards/unknown-card-number/',
        },
        stid: {
            root: '/hardware/stid/',
            assign: '/hardware/stid/assign/',
            logs: '/hardware/stid/logs/',
        },
        acu: {
            root: '/hardware/acu/',
            details: '/hardware/acu/:id/',
        },
    },
    users: {
        root: '/users/',
        moveIns: '/users/move-ins/',
        moveOuts: '/users/move-outs/',
        add: '/users/add/',
        upload: '/users/upload/',
        profile: '/users/profile/:id',
        faceIndex: '/users/profile/:id/face-index/',
        doorAccess: '/users/profile/:id/door-access/',
        credentials: '/users/profile/:id/credentials/',
        assignGroups: '/users/profile/:id/assign-groups/',
        unitAccess: '/users/profile/:id/unit-access/',
        assignUnits: '/users/profile/:id/assign-units/',
    },
    activityFeed: {
        root: '/event-feed',
    },
    sites: {
        root: '/sites/',
        add: '/sites/add/',
        edit: '/sites/edit/:id',
        site: '/sites/:id',
        units: {
            root: '/sites/:id/units/',
            add: '/sites/:siteId/units/add/',
            details: '/sites/:siteId/units/:id/',
        },
        accessPoints: {
            root: '/sites/:id/access-points/',
            add: '/sites/:siteId/access-points/add/',
            details: '/sites/access-point/:id',
            userList: '/sites/access-point/:id/user-list',
            assignUsers: '/sites/access-point/:id/assign-users',
        },
        elevators: {
            root: '/sites/:id/elevators/',
            details: '/sites/:siteId/elevators/:id/',
            add: '/sites/:siteId/elevators/new/',
        },
        floors: {
            root: '/sites/:id/floors/',
            add: '/sites/:siteId/floors/new/',
            details: '/sites/:siteId/floors/:id/',
        },
    },
    schedules: {
        root: '/schedules/',
        new: '/schedules/new/',
        detail: '/schedules/details/:id/',
        doorUnlock: '/schedules/door-unlock/',
        doorUnlockDetails: '/schedules/door-unlock/details/:id/',
        doorUnlockNew: '/schedules/door-unlock/new/',
        exceptions: '/schedules/holidays/',
        addException: '/schedules/holidays/add/',
        editException: '/schedules/holidays/edit/:id/',
    },
    healthCheck: {
        root: '/health-check/',
        activity: '/health-check/activity/',
        announcements: '/health-check/announcements/',
        test: '/health-check-test/',
    },
    integrations: {
        root: '/integrations/',
        singleSignIn: {
            login: '/integrations/:name/',
            sync: '/integrations/:name/sync/',
            groups: '/integrations/:name/sync/groups/',
            logs: '/integrations/:name/logs/',
        },
        eagleEye: {
            root: '/integrations/eagle-eye/:id',
            connectionList: '/integrations/eagle-eye/connections/',
            connection: '/integrations/eagle-eye/connections/:connectionId/',
            doorMapping: '/integrations/eagle-eye/connections/:connectionId/door-mapping/',
            cameras: '/integrations/eagle-eye/connections/:connectionId/cameras/',
        },
        vms: {
            agents: '/integrations/vms/agents/',
            agentDetails: '/integrations/vms/agents/:agentId/',
            addConnection: '/integrations/vms/agents/:agentId/connections/add/',
            editConnection: '/integrations/vms/agents/:agentId/connections/:connectionId/',
            mappings: '/integrations/vms/agents/:agentId/connections/:connectionId/mappings/',
        },
        milestone: {
            agents: '/integrations/milestone/agents/',
            agentDetails: '/integrations/milestone/agents/:agentId/',
            events: '/integrations/milestone/agents/:agentId/events/',
        },
        pms: {
            realPage: {
                root: '/integrations/pms/real-page/',
                connections: '/integrations/pms/real-page/connections/',
                syncLogs: '/integrations/pms/real-page/logs/',
                details: '/integrations/pms/real-page/connections/:id',
            },
            entrata: {
                root: '/integrations/pms/entrata/',
                connections: '/integrations/pms/entrata/connections/',
                syncLogs: '/integrations/pms/entrata/logs/',
                details: '/integrations/pms/entrata/connections/:id',
            },
            yardi: {
                root: '/integrations/pms/yardi/',
                connections: '/integrations/pms/yardi/connections/',
                syncLogs: '/integrations/pms/yardi/logs/',
                details: '/integrations/pms/yardi/connections/:id',
            },
            appfolio: {
                root: '/integrations/pms/appfolio/',
                connections: '/integrations/pms/appfolio/connections/',
                addConnection: '/integrations/pms/appfolio/connections/add/',
                syncLogs: '/integrations/pms/appfolio/logs/',
                fileLogs: '/integrations/pms/appfolio/connections/:id/file-logs/',
                details: '/integrations/pms/appfolio/connections/:id',
            },
            mds: {
                root: '/integrations/pms/mds/',
                connections: '/integrations/pms/mds/connections/',
                addConnection: '/integrations/pms/mds/connections/add/',
                syncLogs: '/integrations/pms/mds/logs/',
                details: '/integrations/pms/mds/connections/:id',
                fileLogs: '/integrations/pms/mds/connections/:id/file-logs/',
            },
        },
        locks: {
            august: {
                root: '/integrations/locks/august/',
                mapLocks: '/integrations/locks/august/:id/map/',
            },
            igloo: {
                root: '/integrations/locks/igloo/',
            },
        },
        accessControlSystems: {
            brivo: {
                root: '/integrations/access-control-systems/brivo/',
                logs: '/integrations/access-control-systems/brivo/logs/',
                edit: '/integrations/access-control-systems/brivo/:id',
            },
        },
    },
    auditLogs: {
        root: '/audit-log',
    },
    tailgatingDetection: {
        root: '/tailgating-detection/',
    },
    settings: {
        root: '/settings',
        api: {
            root: '/api',
            create: '/create/',
        },
    },
    user: {
        root: '/user/',
        profile: {
            root: '/user/profile/',
            faceIndexes: '/user/profile/face-indexes/',
        },
        access: '/user/access/',
        credentials: '/user/credentials/',
        feed: '/feed/',
        pins: '/feed/pins/',
        passes: '/passes/',
        addPass: '/passes/new/',
        passDetail: '/passes/:id/',
        subscriptions: '/account-details/',
    },
    augustRedirect: {
        root: '/august/redirect',
    },
    announcements: {
        root: '/announcements/',
        add: '/announcements/add/',
    },
    commandCenter: {
        root: '/command-center',
        sites: '/command-center/sites/',
        inventoryManagement: {
            root: '/command-center/inventory-management/',
            acuDcu: {
                upload: '/command-center/inventory-management/acu-dcu/upload',
            },
        },
        workspace: {
            root: '/command-center/workspaces/',
            add: '/command-center/workspaces/add/',
            edit: '/command-center/workspaces/detail/:id/',
            settings: '/command-center/workspaces/detail/:id/settings/',
            admins: '/command-center/workspaces/detail/:id/admins/',
            integrations: '/command-center/workspaces/detail/:id/integrations/',
            hardware: '/command-center/workspaces/detail/:id/hardware/',
            billing: '/command-center/workspaces/detail/:id/billing/',
            enterWorkspace: '/command-center/enter-workspace/',
        },
        swiftReaders: {
            root: '/command-center/swift-readers/',
            swiftReaderX: '/command-center/swift-readers/swiftreaderx/',
            details: '/command-center/swift-readers/detail/:id/',
            swiftReaderXDetails: '/command-center/swift-readers/swiftreaderx/detail/:id/',
            detailsTelemetryX: '/command-center/swift-readers/swiftreaderx/detail/:id/telemetry/',
            detailsTelemetry: '/command-center/swift-readers/detail/:id/telemetry/',
            detailsConfigX: '/command-center/swift-readers/swiftreaderx/detail/:id/config/',
            detailsConfig: '/command-center/swift-readers/detail/:id/config/',
        },
        dcu: {
            root: '/command-center/dcu-devices/',
            swiftReaderX: '/command-center/dcu-devices/swiftreaderx/',
            firmware: '/command-center/dcu-devices/firmware/',
            hardware: '/command-center/dcu-devices/hardware/',
            detail: '/command-center/dcu-devices/detail/:deviceId/',
            telemetry: '/command-center/dcu-devices/detail/:deviceId/telemetry/',
            createDCUs: '/command-center/dcu-devices/bulk-create/',
            hardwareCreate: '/command-center/dcu-devices/hardware/create/',
            firmwareCreate: '/command-center/dcu-devices/firmware/create/',
            firmwareDetails: '/command-center/dcu-devices/firmware/:id/',
            hardwareDetails: '/command-center/dcu-devices/hardware/:id/',
        },
        users: {
            root: '/command-center/users/',
            details: '/command-center/users/:id/',
        },
        userFeedback: {
            root: '/command-center/user-feedback/',
        },
        referrals: {
            root: '/command-center/referrals/',
            offers: '/command-center/referrals/offers/',
            addOffer: '/command-center/referrals/offers/add/',
            editOffer: '/command-center/referrals/offers/edit/:id/',
            codes: '/command-center/referrals/codes/',
            addCode: '/command-center/referrals/codes/add/',
        },
        readAccessLogs: {
            root: '/command-center/read-access/',
        },
        vms: {
            root: '/command-center/vms/',
            agentDetails: '/command-center/vms/agents/:agentId/',
            addConnection: '/command-center/vms/agents/:agentId/connections/add/',
            editConnection: '/command-center/vms/agents/:agentId/connections/:connectionId/',
            mappings: '/command-center/vms/agents/:agentId/connections/:connectionId/mappings/',
        },
        acuHub: {
            root: '/command-center/acu-hub/',
            details: '/command-center/acu-hub/device/:id/',
            firmware: {
                root: '/command-center/acu-hub/firmware/',
                add: '/command-center/acu-hub/firmware/add/',
                details: '/command-center/acu-hub/firmware/:id/',
            },
            hardware: {
                root: '/command-center/acu-hub/hardware/',
                add: '/command-center/acu-hub/hardware/add/',
                details: '/command-center/acu-hub/hardware/:id/',
            },
        },
        rentGpt: {
            root: '/rent-gpt/',
        },
        billing: {
            root: '/billing/',
            details: '/billing/:accountId/',
            createTemplates: '/billing/create-templates/',
        },
        invoices: {
            root: '/invoices/',
        },
        attUsageTracking: {
            root: '/att-usage-tracking/',
        },
        deviceManager: {
            root: '/command-center/device-manager/',
            addApp: '/command-center/device-manager/apps/new/',
            editApp: '/command-center/device-manager/apps/:id/',
            appsVersions: '/command-center/device-manager/apps-versions/',
            addAppVersion: '/command-center/device-manager/apps-versions/new/',
            editAppVersion: '/command-center/device-manager/apps-versions/:id/',
            deviceGroups: '/command-center/device-manager/device-groups/',
            addDeviceGroup: '/command-center/device-manager/device-groups/new/',
            editDeviceGroup: '/command-center/device-manager/device-groups/:id/',
            policies: '/command-center/device-manager/policies/',
            addPolicy: '/command-center/device-manager/policies/new/',
            policyDetails: '/command-center/device-manager/policies/:id/',
            policyApps: '/command-center/device-manager/policies/:id/apps/',
            policyGroups: '/command-center/device-manager/policies/:id/groups/',
            devices: '/command-center/device-manager/devices/',
            auditLogs: '/command-center/device-manager/audit-logs',
        },
        partnerManager: {
            root: '/command-center/partner-manager/',
            add: '/command-center/partner-manager/add/',
            edit: '/command-center/partner-manager/:id/',
        },
    },
    rentGpt: {
        root: '/rent-gpt',
        chatBotSessions: '/chat-bot-sessions/',
        contentModeration: {
            root: '/content-moderation',
            add: '/add/',
            edit: '/edit/:id/',
        },
        gallery: {
            root: '/gallery',
        },
        leadActions: {
            root: '/lead-actions',
            details: '/:id/',
        },
        formSubmissions: {
            root: '/form-submissions',
            details: '/:id/',
        },
        settings: {
            root: '/settings',
            chatBotUi: '/chat-bot-ui/',
            actions: '/actions/',
            notificationSettings: '/notification-settings/',
        },
    },
    helpSupport: {
        root: '/help-support',
    },
    billing: {
        root: '/invoices',
    },
    packageDelivery: {
        root: '/package-delivery',
    },
    swiftCall: {
        root: '/swiftcall',
        list: '/swiftcall/:id/call-boxes/',
        add: '/swiftcall/:siteId/call-boxes/new/',
        details: '/swiftcall/:siteId/call-boxes/:id/',
        unitDetails: '/swiftcall/:siteId/call-boxes/:callBoxId/unit/:id/',
        addUnit: '/swiftcall/:siteId/call-boxes/:callBoxId/unit/new/',
    },
};
